<!-- 班级登记管理 -->
<template>
    <div class="ClassRegister">
        <el-form ref="form" :model="form" inline class="form" label-width="120px">
            <el-form-item label="日期查询">
                <el-date-picker
                value-format="yyyy-MM-dd"
                    v-model="form.dateQuery"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="选择学科">
                <el-select v-model="form.subject" filterable placeholder="请输入并选择学科" :clearable="true">
                    <el-option
                      v-for="item in form.subjectOpt"
                      :key="item.id"
                      :label="item.course_name"
                      :value="item.id">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="班级状态">
                <el-select v-model="form.classState" placeholder="请选择" :clearable="true">
                    <el-option
                      v-for="item in form.classStateOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="缴费状态">
                <el-select v-model="form.payState" placeholder="请选择" :clearable="true">
                    <el-option
                      v-for="item in form.payStateOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="班类型">
                <el-select v-model="form.classType" placeholder="请选择" :clearable="true">
                    <el-option
                      v-for="item in form.classTypeOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="搜索">
                <el-input v-model="form.search" placeholder="请输入关键字"></el-input>
            </el-form-item>
        </el-form>
        <!-- 按钮功能部分 -->
        <div class="" style="display: flex;justify-content: space-between;">
            <div class="" style="margin-left:22px ;">
                <el-button size="small" icon="el-icon-refresh" 
                    style="background-color: #2C3E50; border-radius: 3px;"
                    @click="refresh">
                    <!-- 刷新 -->
                </el-button>
               <!-- <el-button size="small" icon="el-icon-plus" style="background: #18BC9C;color: #FFFFFF;"
                    @click="newlyAdded">
                    新增
                </el-button> -->
                <!-- <button class="print">打印预览</button> -->
                <button class="more" @click="more">更多</button>
            </div>
            <div class="" style="margin-right: 10px;">
                <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="query">
                    查询
                </el-button>
                <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="reset">
                    重置
                </el-button>
            </div>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:97.9%;flex: 1;" 
        height="650" @selection-change="checkAll" @sort-change="abc">
            <el-table-column type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="is_approve" sortable="custom"  label="审核状态" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.is_approve==1">已审核</span>
                    <span v-else-if="scope.row.is_approve==2">未审核</span>
                </template>
            </el-table-column>
            <el-table-column prop="class_status" sortable="custom"  label="班级状态" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.class_status==1">开班</span>
                    <span v-else-if="scope.row.class_status==2">取消</span>
                    <span v-else-if="scope.row.class_status==3">结业</span>
                </template>
            </el-table-column>
            <el-table-column prop="class_type" sortable="custom"  label="班类型" align="center" width="100">
                <template slot-scope="scope">
                    <span v-if="scope.row.class_type==1">春季</span>
                    <span v-else-if="scope.row.class_type==2">秋季</span>
                    <span v-else-if="scope.row.class_type==3">寒假</span>
                    <span v-else-if="scope.row.class_type==4">暑假</span>
                    <span v-else-if="scope.row.class_type==5">考前培训</span>
                </template>
            </el-table-column>
            <el-table-column prop="number" sortable="custom"  label="班级编号" align="center" width="150">
            </el-table-column>
            <el-table-column prop="class_name" sortable="custom"  label="班级名称" align="center" width="200">
            </el-table-column>
            <el-table-column prop="resources" sortable="custom"  label="授课老师" align="center" width="120">
            </el-table-column>
            <el-table-column prop="course_style2" sortable="custom"  label="学科类" align="center" width="120">
            </el-table-column>
            <el-table-column prop="course" sortable="custom"  label="学科" align="center" width="120">
            </el-table-column>
            <el-table-column prop="lecture_number" sortable="custom"  label="授课次数" align="center" width="120">
            </el-table-column>
            <el-table-column prop="already_lecture_number" sortable="custom"  label="已授课次数" align="center" width="120">
            </el-table-column>
            <el-table-column prop="actual_size" sortable="custom"  label="实际人数" align="center" width="120">
            </el-table-column>
            <el-table-column prop="planned_size" sortable="custom"  label="计划人数" align="center" width="120">
            </el-table-column>
            <el-table-column prop="remark" sortable="custom"  label="班级说明" align="center" width="200">
            </el-table-column>
            <el-table-column prop="classroom" sortable="custom"  label="上课教室" align="center" width="200">
            </el-table-column>
            <el-table-column prop="start_time" sortable="custom"  label="开班日期" align="center" width="150">
            </el-table-column>
            <el-table-column prop="end_time" sortable="custom"  label="结业日期" align="center" width="150">
            </el-table-column>
            <el-table-column prop="entrance_graduation" sortable="custom"  label="入学与结业人数" align="center" width="150">
            </el-table-column>
            <el-table-column prop="tuition_incidentals" sortable="custom"  label="标准学杂费" align="center" width="120">
            </el-table-column>
            <el-table-column prop="tuition" sortable="custom"  label="标准学费" align="center" width="120">
            </el-table-column>
            <el-table-column prop="incidentals" sortable="custom"  label="杂费" align="center" width="120">
            </el-table-column>
            <el-table-column prop="planned_amount" sortable="custom"  label="计划标准应收" align="center" width="150">
            </el-table-column>
            <el-table-column prop="after_discount" sortable="custom"  label="优惠后应收学杂费" align="center" width="180">
            </el-table-column>
            <el-table-column prop="train_tuition" sortable="custom"  label="应收学费" align="center" width="120">
            </el-table-column>
            <el-table-column prop="actual_amount" sortable="custom"  label="优惠后实收" align="center" width="120">
            </el-table-column>
            <el-table-column prop="after_uncollected" sortable="custom"  label="优惠后未收" align="center" width="120">
            </el-table-column>
            <el-table-column prop="transfer_tuition" sortable="custom"  label="转入学费" align="center" width="120">
            </el-table-column>
            <el-table-column prop="refund_amount" sortable="custom"  label="退费金额" align="center" width="120">
            </el-table-column>
            <el-table-column prop="enrollment_rate" sortable="custom"  label="招生率(%)" align="center" width="120">
            </el-table-column>
            <el-table-column prop="revenue_rate" sortable="custom"  label="收入完成率(%)" align="center" width="150">
            </el-table-column>
            <el-table-column prop="document_time" sortable="custom"  label="制单日期" align="center" width="150">
            </el-table-column>
            <el-table-column prop="arrange_number" sortable="custom"  label="单号" align="center" width="150">
            </el-table-column>
            <el-table-column prop="add_name" sortable="custom"  label="录入人" align="center" width="120">
            </el-table-column>
            <el-table-column prop="add_time" sortable="custom"  label="录入时间" align="center" width="200">
            </el-table-column>
            <el-table-column prop="update_name" sortable="custom"  label="修改人" align="center" width="120">
            </el-table-column>
            <el-table-column prop="update_time" sortable="custom"  label="最近修改时间" align="center" width="200">
            </el-table-column>
            <el-table-column  label="操作" align="center" fixed="right" width="200">
                <template slot-scope="scope">
                    <el-button class="stu" size="mini" @click="Trainee(scope.$index, scope.row)">
                        学员
                    </el-button>
                    <!-- <el-button icon="el-icon-edit" size="mini" style="background:#18BC9C;color: #fff;" 
                     @click="edit(scope.$index, scope.row)">
                        编辑
                    </el-button> -->
                    <el-button  icon="el-icon-delete" size="mini" style="background:#E74C3C;color: #fff;">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" 
        @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form:{
                    dateQuery:"",//日期查询
                    subject:"",//选择学科
                    subjectOpt:[],//学科选项
                    classState:0,//班级状态
                    classStateOpt:[
                        {label: "全部", value: 0},
                        {label: "入学", value: 1},
                        {label: "结业", value: 2},
                        {label: "中止", value: 3},
                    ],//班级状态选项
                    payState:0,//缴费状态
                    payStateOpt:[
                        {label: "全部", value: 0},
                        {label: "已过账", value: 1},
                        {label: "未过账", value: 2},
                        {label: "未确认", value: 3},
                    ],//缴费状态选项
                    classType:0,//班类型
                    classTypeOpt:[
                        {label: "全部", value: 0},
                        {label: "春季", value: 1},
                        {label: "秋季", value: 2},
                        {label: "寒假", value: 3},
                        {label: "暑假", value: 4},
                        {label: "考前培训", value: 5}
                    ],//班类型选项
                    search:""//搜索
                },
                tableData:[],
                currentPage: 1,
                currentLimit: 20, //条数
                total: 0,
                id:"",//接收班级ID
                arr:[]
            }
        },
        created() {
            // 学科数据
            this.$request({
                url:'/api/course/list',
                method:'POST',
                data:{
                    page:1,
                    limit:100
                }
            }).then(res=>{
                if(res.code==1){
                    this.form.subjectOpt=res.data.list
                }
            })
            
            this.getList()
        },
        activated() {
            this.getList()
        },
        methods: {
            // 获取列表
            getList(){
                this.$request({
                    url:'/api/class_/managementList',
                    method:'POST',
                    data:{
                        page:this.currentPage,
                        limit:this.currentLimit,
                        sort:this.arr
                    }
                }).then(res=>{
                    if(res.code==1){
                        console.log(res,'列表')
                        this.tableData=res.data.list
                        this.total=res.data.count
                    }
                })
            },
            handleSizeChange(val) {
                this.currentLimit=val
                this.getList()
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.getList()
                // console.log(`当前页: ${val}`);
            },
            query(){//查询
                this.currentPage=1
                this.getList()
            },
            reset(){//重置
                this.form.dateQuery=""//日期查询
                this.form.subject=""//选择学科
                this.form.classState=0//班级状态
                this.form.payState=0//缴费状态
                this.form.classType=0//班类型
                this.form.search=""//搜索
                this.getList()
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            checkAll(selection){
                // console.log(selection)
                this.id = ''
                selection.forEach(item => {
                    this.id = item.id
                })
            },
            newlyAdded() { //新增
                this.$router.push({
                    path: "addClassRegister"
                })
            },
            edit(index,row) { //编辑
                this.$router.push({
                    path: "editClassRegister"
                })
            },
            more(){//更多
                if(this.id!=''){
                    this.$router.push({
                        path: "classRegisterMore",
                        query:{
                            class_id:this.id
                        }
                    })
                }else{
                    this.$message({
                        message: '班级id不能为空，请选择',
                        type: 'error'
                    })
                }
                
            },
            Trainee(idnex,row){//学员
                this.$router.push({
                    path: "trainee",
                    query:{
                        id:row.id
                    }
                })
            },
            // 姓名排序
            fn2(a, b){
                return a.resources.localeCompare(b.resources)
            },
            //排序
            abc(i){ 
                console.log(i)
                this.tableData=[]
                this.arr=[]
                this.arr.push({
                    title:i.prop,
                    order:i.order
                })
                console.log(this.arr)
                this.getList()
            }
        }

    }
</script>

<style scoped="scoped">
    .ClassRegister {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 查询部分 */
    .form {
        padding-top: 34px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    
    .form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    }
    
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    /* 按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }
    
    ::v-deep .el-icon-plus {
        font-weight: bold !important;
    }
    /* 打印预览 */
    .print{
        display: inline-block;
        padding-left: 10px;
        width: 94px;
        height: 30px;
        border-radius: 3px;
        vertical-align: middle;
        background: url(../../assets/print.png)8px 9px no-repeat #18BC9C;
        border: none;
        color: #FFFFFF;
        margin:0px 10px;
    }
    /* 更多 */
    .more{
        display: inline-block;
        width: 70px;
        height: 30px;
        vertical-align: middle;
        background: url(../../assets/more.png) 10px 8px no-repeat #29726C;
        color: #FFFFFF;
        border-radius: 3px;
        padding-left: 15px;
        margin-left: 10px;
        cursor: pointer;
        border: none;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }
    .stu{
        display: inline-block;
        padding-left: 35px;
        background: url(../../assets/stud.png)10px 7px no-repeat #FF7300;
        color: #FFFFFF;
        font-size: 12px;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
</style>
